import { Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { MODALS, modalAtom } from "../atoms/modal-atom";
import { createProxy } from "../utils/proxy-utils";

export function CreateProxyModal() {
    const [openModal, setOpen] = useAtom(modalAtom);
    const [proxyUrl, setProxyUrl] = useState("");
    const open = openModal === MODALS.CREATE_PROXY;

    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setOpen("");
        setProxyUrl("");
    };

    function handleCreateProxy() {
        if (proxyUrl.trim() === "") return;

        createProxy(proxyUrl)
            .then(async (res) => {
                console.log(res.ok, res);
                if (!res.ok) {
                    throw new Error(await res.text());
                }

                enqueueSnackbar("Proxy criada com sucesso", { variant: "success" });
                handleClose();
            })
            .catch((err) => {
                console.error("Failed to create proxy", err, err instanceof Error);

                if (err instanceof Error) {
                    enqueueSnackbar(err.message, { variant: "error" });
                } else {
                    enqueueSnackbar("Falha ao criar proxy, tente novamente", { variant: "error" });
                }
            });
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        width: 400,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography mb={1} variant="h5">
                        Criar Proxy
                    </Typography>

                    <TextField
                        variant="standard"
                        label={"URL da Proxy"}
                        value={proxyUrl}
                        onChange={(event) => setProxyUrl(event.target.value)}
                    />

                    <Button variant="contained" sx={{ marginTop: 3 }} onClick={handleCreateProxy}>
                        Criar
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
}
