import { BASE_URL } from "../constants/urls";

interface QrcodeConnection {
    instance: {
        instanceName: string;
        state: string;
    };
}
interface QrcodeConnection {
    pairingCode: string | undefined;
    code: string;
    base64: string;
    count: number;
}

export function getQrCodeFromInstance(instanceName: string): Promise<QrcodeConnection> {
    return fetch(`${BASE_URL}/instance/qrcode?instanceName=${instanceName}`)
        .then((res) => res.json())
        .catch((err) => {
            console.error(`Failed to fetch qrcode from instance ${instanceName}`, err);
            return undefined;
        });
}
