import {
    Box,
    Button,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { MODALS, modalAtom } from "../atoms/modal-atom";
import { createInstance, refreshInstances } from "../utils/instance-utils";
import { fetchProxies } from "../utils/proxy-utils";

export function CreateInstanceModal() {
    const [openModal, setOpen] = useAtom(modalAtom);
    const [instanceName, setInstanceName] = useState("");
    const [selectedProxy, setSelectedProxy] = useState("");
    const [proxies, setProxies] = useState([]);
    const open = openModal === MODALS.CREATE_INSTANCE;

    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const handleClose = () => {
        setOpen("");
        setSelectedProxy("");
        setInstanceName("");
        setLoading(false);
    };

    function handleCreateInstance() {
        if (instanceName.trim() === "") return;
        if (selectedProxy.trim() === "") return;

        enqueueSnackbar("Criando instância, aguarde...");
        setLoading(true);

        createInstance(instanceName, selectedProxy)
            .then(() => {
                enqueueSnackbar(`Instância ${instanceName} criada com sucesso!`, { variant: "success" });
                handleClose();
                refreshInstances();
            })
            .catch((err) => {
                console.error("Failed to create instance", err);
                enqueueSnackbar("Falha ao criar instância, tente novamente", { variant: "error" });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (!open) return;
        fetchProxies().then((proxies) => setProxies(proxies));
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        width: 400,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography mb={1} variant="h5">
                        Criar Instância
                    </Typography>

                    <TextField
                        variant="standard"
                        label={"Nome"}
                        value={instanceName}
                        onChange={(event) => setInstanceName(event.target.value)}
                    />

                    <FormControl variant="standard">
                        <InputLabel>Proxy</InputLabel>
                        <Select
                            value={selectedProxy}
                            onChange={(event) => setSelectedProxy(event.target.value)}
                            label="Proxy"
                        >
                            {proxies.map((proxy, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <MenuItem key={proxy + index} value={proxy}>
                                    {proxy}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button disabled={loading} variant="contained" sx={{ marginTop: 3 }} onClick={handleCreateInstance}>
                        Criar
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
}
