import Grid from "@mui/material/Unstable_Grid2";
import { useAtomValue } from "jotai";
import { instancesAtom } from "../atoms/instances-atom";
import { InstanceCard } from "./InstanceCard";

export function InstanceContainer() {
    const instances = useAtomValue(instancesAtom);

    return (
        <Grid container margin={2} spacing={2}>
            {instances.map((instance) => (
                <Grid key={instance.id}>
                    <InstanceCard instance={instance} />
                </Grid>
            ))}
        </Grid>
    );
}
