import { Box, Button, Fade, Modal } from "@mui/material";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import type { Instance } from "../atoms/instances-atom";
import { MODALS, modalAtom } from "../atoms/modal-atom";
import { getQrCodeFromInstance } from "../utils/qrcode-utils";
import { refreshInstances } from "../utils/instance-utils";

export function QrCodeModal({ instance }: { instance: Instance }) {
    const [openModal, setOpen] = useAtom(modalAtom);
    const open = openModal === MODALS.QRCODE_CONNECT + instance.name;
    const [qrcodeBase64, setQrcodeBase64] = useState<string | undefined>();

    const { enqueueSnackbar } = useSnackbar();
    const handleClose = useCallback(() => {
        setOpen("");
    }, [setOpen]);

    const reloadQrCode = useCallback(() => {
        getQrCodeFromInstance(instance.name)
            .then((qrcode) => {
                if (qrcode.instance) {
                    handleClose();
                    refreshInstances().catch(() => undefined);
                    return;
                }
                setQrcodeBase64(qrcode.base64);
                console.log(qrcode);
            })
            .catch(() => {
                enqueueSnackbar("Falha ao recarregar qrcode", { variant: "error" });
            });
    }, [instance, enqueueSnackbar, handleClose]);

    useEffect(() => {
        if (!open) return;

        reloadQrCode();
        const intervalId = setInterval(reloadQrCode, 10000);

        return () => clearInterval(intervalId);
    }, [open, reloadQrCode]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        width: 400,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {qrcodeBase64 && <img src={qrcodeBase64} alt="qrcode" />}

                    <Button variant="contained" sx={{ marginTop: 3 }} onClick={reloadQrCode}>
                        Recarregar
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
}
