import { BASE_URL } from "../constants/urls";

export async function fetchProxies() {
    try {
        const res = await fetch(`${BASE_URL}/proxy`);
        return await res.json();
    } catch (err) {
        console.error("Failed to fetch proxies", err);
        return [];
    }
}

export function createProxy(url: string) {
    return fetch(`${BASE_URL}/proxy`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            url,
        }),
    });
}
