import { SnackbarProvider } from "notistack";
import { CreateInstanceModal } from "./components/CreateInstanceModal";
import { CreateProxyModal } from "./components/CreateProxyModal";
import { InstanceContainer } from "./components/InstanceContainer";
import { Topbar } from "./components/Topbar";

function App() {
    return (
        <div>
            <SnackbarProvider maxSnack={5}>
                <Topbar />
                <InstanceContainer />
                <CreateInstanceModal />
                <CreateProxyModal />
            </SnackbarProvider>
        </div>
    );
}

export default App;
