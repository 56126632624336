import { Delete, QrCode2 } from "@mui/icons-material";
import { Box, Card, CardActions, CardContent, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import type { Instance } from "../atoms/instances-atom";
import { deleteInstance, refreshInstances } from "../utils/instance-utils";
import { useSetAtom } from "jotai";
import { modalAtom, MODALS } from "../atoms/modal-atom";
import { QrCodeModal } from "./QrCodeModal";

export function InstanceCard({ instance }: { instance: Instance }) {
    const setOpenModal = useSetAtom(modalAtom);
    const { enqueueSnackbar } = useSnackbar();
    const connected = !!instance.number;

    async function handleDelete() {
        await deleteInstance(instance.name)
            .then(() => {
                enqueueSnackbar(`Instância ${instance.name} deletada com sucesso`, { variant: "success" });
                refreshInstances();
            })
            .catch(() => {
                enqueueSnackbar("Falha ao deletar instância, tente novamente", { variant: "error" });
            });
    }

    function handleConnection() {
        setOpenModal(MODALS.QRCODE_CONNECT + instance.name);
    }

    return (
        <>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography>Nome: {instance.name}</Typography>
                    {instance.number && <Typography>Número: {instance.number}</Typography>}
                </CardContent>

                <CardActions sx={{ justifyContent: "space-between" }}>
                    <Box>
                        <Chip
                            label={connected ? "Conectado" : "Desconectado"}
                            variant="outlined"
                            color={connected ? "success" : "error"}
                        />
                    </Box>

                    <Box>
                        {!connected && (
                            <Tooltip title="Conectar" arrow>
                                <IconButton size="small" onClick={handleConnection}>
                                    <QrCode2 />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip title="Deletar" arrow>
                            <IconButton size="small" onClick={handleDelete}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </CardActions>
            </Card>
            <QrCodeModal instance={instance} />
        </>
    );
}
