import { getDefaultStore } from "jotai";
import { type Instance, instancesAtom } from "../atoms/instances-atom";
import { BASE_URL } from "../constants/urls";

export async function fetchInstances(): Promise<Instance[] | undefined> {
    return fetch(`${BASE_URL}/instances`)
        .then((res) => res.json())
        .catch((err) => {
            console.error("Failed to fetch instances", err);
            return undefined;
        });
}

export async function createInstance(name: string, proxy: string) {
    return fetch(`${BASE_URL}/instance`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name,
            proxy,
        }),
    }).then((res) => {
        if (res.ok) return res;
        throw new Error("Failed to fetch");
    });
}

export async function deleteInstance(instanceName: string) {
    const res = await fetch(`${BASE_URL}/instance`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            instanceName,
        }),
    });
    if (res.ok) return res;
    throw new Error("Failed to fetch");
}

export async function refreshInstances() {
    const instances = await fetchInstances();
    if (!instances) return Promise.reject();

    const store = getDefaultStore();
    store.set(instancesAtom, instances);
}

export async function refreshInstances2() {
    return fetch(`${BASE_URL}/refresh`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.ok) return res;
        return res;
        // throw new Error("Failed to refresh");
    });
}

