import { Refresh } from "@mui/icons-material";
import { AppBar, Button, CircularProgress, Fade, IconButton, Toolbar, Typography } from "@mui/material";
import { useSetAtom } from "jotai";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { MODALS, modalAtom } from "../atoms/modal-atom";
import { refreshInstances, refreshInstances2 } from "../utils/instance-utils";

export function Topbar() {
    const { enqueueSnackbar } = useSnackbar();
    const setModalOpen = useSetAtom(modalAtom);
    const [loading, setLoading] = useState(false);

    const refresh = useCallback(() => {
        setLoading(true);
        refreshInstances()
            .catch(() => {
                enqueueSnackbar("Falha ao recarregar instâncias", { variant: "error" });
            })
            .finally(() => setLoading(false));
    }, [enqueueSnackbar]);

    const refresh2 = () => {
        refresh()
        refreshInstances2()
    }

    useEffect(() => refresh(), [refresh]);

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography sx={{ flexGrow: 1 }} component="div" variant="h5">
                    Dashboard
                </Typography>

                <IconButton disabled={loading} color="inherit" onClick={refresh2}>
                    {loading ? (
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? "800ms" : "0ms",
                            }}
                            unmountOnExit
                        >
                            <CircularProgress size={"1.5rem"} />
                        </Fade>
                    ) : (
                        <Refresh />
                    )}
                </IconButton>

                <Button color="inherit" onClick={() => setModalOpen(MODALS.CREATE_INSTANCE)}>
                    Criar Instância
                </Button>

                <Button color="inherit" onClick={() => setModalOpen(MODALS.CREATE_PROXY)}>
                    Criar Proxy
                </Button>
            </Toolbar>
        </AppBar>
    );
}
